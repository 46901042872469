<template>
  <div>
    <div class="p-b-10">
      <h4 class="workplatform-title m-t-10 m-b-20">上刊订单详情</h4>
      <Card>
        <Row class="p-b-10">
          <i-col span="8">
            <span class="title">投放客户</span>{{ orderInfo.advertiserName }}
          </i-col>
          <i-col span="8" v-show="orderInfo.brandName">
            <span class="title">发布品牌</span>{{ orderInfo.brandName }}
          </i-col>
          <i-col span="8">
            <span class="title">创建时间</span>{{ orderInfo.createTime }}
          </i-col>
        </Row>
        <Row>
          <i-col span="8">
            <span class="title">发布档期</span>
            <Tag color="#fff"><span style="color:#141414">{{ orderInfo.startDate }} 至 {{ orderInfo.endDate }}</span>
            </Tag>
          </i-col>
          <i-col span="16" v-show="orderInfo.remark">
            <span class="title">订单备注</span>{{ orderInfo.remark }}
          </i-col>
        </Row>
      </Card>
    </div>
    <div class="p-t-10">
      <h4 class="workplatform-title m-t-10 m-b-20">上刊设置列表</h4>
      <Tabs v-model="query.type" @on-click="handleSwitchType">
        <TabPane v-for="item in showTypeArray" :key="item.value" :label="item.name" :name="item.value.toString()">
          <!-- <Row
            :gutter="8"
            class="m-b-10"
          >
            <i-col span="4"></i-col>
            <i-col span="4"></i-col>
          </Row> -->
          <template v-if="query.type === item.value.toString()">
            <div v-if="query.type === '1'" class="m-b-5">
              <Button type="primary" size="small" :disabled="selectedTaskIds.length === 0"
                @click="handelSetMonitor">发起监播</Button>
            </div>
            <Table stripe size="small" ref="selection" :data="tableData" :columns="demandColumns" :loading="dataLoading"
              @on-selection-change="selectionChange"></Table>
          </template>
        </TabPane>
        <!-- <Button
          type="primary"
          size="small"
          slot="extra"
          @click="handleSetPublishRes"
        >当前在刊资源</Button> -->
      </Tabs>
    </div>
  </div>
</template>

<script>
import em from '@/utils/enum'
import { sysMixins } from '@/assets/mixins/sys'
// import { formatDemandStatus } from '@/utils/tagStatus'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { revocation } from '@/api/msp/demand'
import { deleteTask, getUninstallTaskPage } from '@/api/msp/task'
import { openCreateUninstallFlag } from '@/api/msp/uninstall'

export default {
  mixins: [sysMixins],
  props: {
    serviceType: { // 服务对象类型，1：销售，2：代理商
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      showTypeArray: em.installShowTaskType,
      // orderInfo: {},
      query: {
        pageNumber: 1,
        pageSize: 50,
        orderId: null,
        startDate: '',
        endDate: '',
        type: this.$store.state.installSetting.demandType ? this.$store.state.installSetting.demandType.toString() : em.installShowTaskType[0].value.toString()
      },
      demandList: [],
      showDemandColumns: new Map([
        [1, ['selection', 'publishDate', 'createTime', 'resource', 'count', 'flag', 'isCreateUninstall', 'operate']],
        [2, ['publishDate', 'name', 'createTime', 'resource', 'count', 'operate']],
        [7, ['publishDate', 'createTime', 'resource', 'totalQuantity', 'operate']]
      ]),
      demandColumns: [],
      dataLoading: false
    }
  },
  computed: {
    orderId () {
      return this.$store.state.installSetting.demandOrderId
    },
    orderInfo () {
      return this.$store.state.installSetting.orderInfo
    },
    demandType: {
      get () {
        return this.$store.state.installSetting.demandType
      },
      set (val) {
        this.$store.commit('set_demand_type', val)
      }

    },
    selectedTaskIds: {
      get () {
        return this.$store.state.installSetting.selectedTaskIds
      },
      set (val) {
        this.$store.commit('set_selected_taskIds', val)
      }
    },
    tableData: {
      get () {
        const result = this.$store.state.installSetting.tableData.list
        if (parseInt(this.query.type) === 1 && result && result.length) {
          result.forEach(item => {
            // 处理显示上刊设置内容
            item._disabled = item.status === -1
          })
        }
        return result
      },
      set (val) {
        this.$store.commit('set_table_data', val)
      }

    }
  },
  created () {
    this.setLinkTagArray({ key: 'installList', value: '上刊设置列表', actived: true })
    this.setActivedTagName('installList')
    this.$store.commit('set_operating_type', 1)
  },
  destroyed () {
    this.tableData = []
    this.selectedTaskIds = []
    this.$store.commit('set_page_query', {})
  },
  methods: {
    /**
       * 动态获取不同类型的列表
       */
    getTableColumns () {
      const tableColumnArray = {
        selection: { type: 'selection', width: 60, align: 'center' },
        publishDate: {
          title: '发布档期',
          render: (h, params) => {
            return h('span', GetCurrentSchedule(params.row.taskOrder.startDate, params.row.taskOrder.endDate))
          }
        },
        name: { title: '名称', ellipsis: true, tooltip: true, key: 'name' },
        createTime: { title: '发起时间', key: 'createTime' },
        actionDate: { title: parseInt(this.query.type) === 2 ? '监播日期' : '作业日期', key: 'actionDate' },
        resource: {
          title: '设置资源',
          render: (h, params) => {
            const spans = []
            params.row.resourcetypeQuantity.forEach(resource => {
              spans.push(h(
                'span',
                resource.resourcetypeName
              ))
              spans.push(h(
                'span',
                {
                  attrs: {
                    style: 'font-size:12px;color:#FA8B59'
                  }
                },
                resource.quantity
              ))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        count: {
          title: '总数/不执行/已派单/执行中/已完成',
          width: 220,
          render: (h, params) => {
            return h('div', [
              h('span', {
                style: {
                  color: params.row.statusQuantity.draftQuantity + params.row.statusQuantity.waittingQuantity > 0 ? '#ef4f4f' : ''
                }
              }, params.row.statusQuantity.totalQuantity),
              h('span', ` / ${params.row.statusQuantity.terminatedQuantity} / ${params.row.statusQuantity.assignedQuantity} / ${params.row.statusQuantity.executingQuantity} / ${params.row.statusQuantity.finishedQuantity}`)
            ])
          }
        },
        totalQuantity: {
          title: '总数',
          width: 220,
          render: (h, params) => {
            return h('span', params.row.statusQuantity.totalQuantity)
          }
        },
        flag: {
          title: '创建方式',
          width: 120,
          render: (h, params) => {
            return h('span', params.row.basicdata === 2 ? '自动生成' : '人工创建')
          }
        },
        isCreateUninstall: {
          title: '创建下刊申请',
          align: 'center',
          width: 120,
          render: (h, params) => {
            let color = 'default'
            let text = '未创建'
            if (params.row.isCreateUninstall) {
              color = 'primary'
              text = '已创建'
            }
            return h('Tag', { props: { color } }, text)
          }
        },
        // status: {
        //   title: '状态',
        //   align: 'center',
        //   render: (h, params) => {
        //     return formatDemandStatus(h, params.row.status)
        //   }
        // },
        operate: {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            var innerHtml = []
            if (params.row.statusQuantity.totalQuantity) {
              innerHtml.push(h('a', {
                style: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.handleViewDetail(params.row)
                  }
                }
              }, '详情'))
            }
            if (parseInt(this.query.type) === 1) {
              if (!params.row.isCreateUninstall) {
                // 未开启下刊申请，显示开启按钮
                innerHtml.push(h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.onEnableUninstall(params.row.id)
                    }
                  }
                }, '启用下刊申请'))
              }
            }
            if (parseInt(this.query.type) !== 7) { // 非下刊
              if (params.row.status === -1) {
                // 草稿状态
                if (this.demandType !== 2 && params.row.basicdata === 1) {
                  // 非监播任务且人工创建
                  innerHtml.push(h('a', {
                    style: { marginRight: '5px' },
                    on: {
                      click: () => {
                        this.handleViewEdit(params.row)
                      }
                    }
                  }, '添加'))
                }
              }
              if (params.row.basicdata === 1) {
                // 人工创建具备删除功能
                if (params.row.status === -1 || params.row.statusQuantity.totalQuantity === 0) {
                  innerHtml.push(h('a', {
                    style: { marginRight: '5px', color: '#ef4f4f' },
                    on: {
                      click: () => {
                        this.handleDeleteTask(params.row.id)
                      }
                    }
                  }, '删除'))
                }
              }
            }
            return h('div', innerHtml)
          }
        }
      }
      const data = []
      const showColumns = this.showDemandColumns.get(parseInt(this.query.type))
      if (showColumns && showColumns.length) {
        showColumns.forEach(col => data.push(tableColumnArray[col]))
      }

      return data
    },
    getOrderInfo () {
      this.$store.dispatch('getOrderInfo')
    },
    getTaskPageData () {
      this.query.orderId = this.orderId
      this.dataLoading = true
      this.tableData = {}
      if (parseInt(this.query.type) === 7) {
        // 下刊数据获取
        getUninstallTaskPage(this.query).then(res => {
          if (res && !res.errcode) {
            this.tableData = res
          } else {
            this.tableData = {}
          }
        }).finally(() => { this.dataLoading = false })
      } else {
        this.$store.dispatch('getTaskPageData', this.query).finally(() => { this.dataLoading = false })
      }

      this.$nextTick(() => {
        this.demandColumns = this.getTableColumns()
      })
    },
    /**
     * 切换tabs事件
     */
    handleSwitchType (name) {
      this.query.type = name
      this.demandType = parseInt(name)
      // this.demandColumns = this.getTableColumns()
      // this.getDemandData()
      this.getTaskPageData()
    },
    /**
     * 查看上刊设置详情
     */
    handleViewDetail (params) {
      this.$store.commit('set_basic_data', params.basicdata)
      this.$store.commit('set_demand_startDate', params.taskOrder.startDate)
      this.$store.commit('set_demand_endDate', params.taskOrder.endDate)
      this.$store.commit('set_current_taskId', params.id)
      this.$store.commit('set_demand_status', params.status)
      this.$store.commit('set_demand_type', parseInt(this.query.type))
      this.$store.commit('set_operating_type', 2)
      this.handlePage()
      // 设置头部路由标签
      this.setLinkTagArray({ key: 'installSetDetail', value: `${this.showTypeArray.find(x => x.value === parseInt(this.query.type)).name}详情` })
      this.setLinkRouterMaps(['installSetDetail', () => { this.handlePage() }])
      this.setActivedTagName('installSetDetail')
    },
    /**
     * 处理页面显示
     */
    handlePage () {
      this.setShowLeftBlock(false)
      this.setLeftComponent('')
      this.setRightComponent('ProgressOverview')
      if (parseInt(this.query.type) === 7) {
        // 下刊详情页面设置
        this.setBottomComponent('UninstallDetial')
      } else {
        this.setBottomComponent('DemandDetail')
      }
    },
    /**
     * 撤销提交
     */
    handleRevokeSubmit (params) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要撤销提交？',
        onOk: () => {
          revocation({ demandId: params.id }).then(res => {
            if (res && res.errcode === 0) {
              // this.getDemandData()
              this.getTaskPageData()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    // /**
    //  * 查看在刊资源
    //  */
    // handleSetPublishRes () {
    //   this.$store.dispatch('getOrderPublishProductArray')
    //   this.handleMonitorPage()
    //   // 设置头部路由标签
    //   this.setLinkTagArray({ key: 'installPublishRes', value: '当前在刊资源' })
    //   this.setLinkRouterMaps(['installPublishRes', () => { this.handleMonitorPage() }])
    //   this.setActivedTagName('installPublishRes')
    // },

    /**
     * 跳转编辑页面
     */
    handleViewEdit (params) {
      this.$store.commit('set_basic_data', params.basicdata)
      this.$store.commit('set_current_taskId', params.id)
      this.$store.commit('set_demand_startDate', params.taskOrder.startDate)
      this.$store.commit('set_demand_endDate', params.taskOrder.endDate)
      this.$store.commit('set_operating_type', 1)
      this.$store.dispatch('getOrderProductArray')
      this.handleEditPage()
      // 设置头部路由标签
      // this.setLinkTagArray({ key: 'installSetScreen', value: '上刊画面设置', actived: true })
      this.setLinkRouterMaps(['installSetScreen', () => { this.handleEditPage() }])
    },
    /**
     * 删除任务
     */
    handleDeleteTask (id) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要删除该条数据',
        onOk: () => {
          deleteTask({ taskId: id }).then(res => {
            if (res && res.errcode === 0) {
              // this.getDemandData()
              this.getTaskPageData()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    /**
     * 启用下刊申请
     */
    onEnableUninstall (id) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要启用下刊申请？启用后将无法关闭。',
        onOk: () => {
          openCreateUninstallFlag({ taskId: id }).then(res => {
            if (res && res.errcode === 0) {
              this.getTaskPageData()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    },
    // /**
    //  * 处理跳转在刊资源界面显示
    //  */
    // handleMonitorPage () {
    //   this.setLeftComponent('')
    //   this.setShowLeftBlock(false)
    //   this.setLeftComponent('')
    //   this.setRightComponent('MonitorOverview')
    //   this.setBottomComponent('RelaseResSetting')
    // },
    selectionChange (selection) {
      if (selection.length) {
        const sDateArray = Array.from(new Set(selection.map(x => x.startDate)))
        const eDateArray = Array.from(new Set(selection.map(x => x.endDate)))

        if (sDateArray.length === 1 && eDateArray.length === 1) {
          this.selectedTaskIds = selection.map(x => x.id)
        } else {
          this.selectedTaskIds = []
          this.$Notice.warning({ desc: '请选择相同档期的上刊设置发起监播' })
        }
      } else {
        this.selectedTaskIds = []
      }
    },
    handelSetMonitor () {
      this.setLeftComponent('MonitorDemand')
    },
    /**
     * 处理编辑页面显示
     */
    handleEditPage () {
      this.setShowLeftBlock(false)
      this.setLeftComponent('')
      this.setRightComponent('SettingOverview')
      // if (this.demandType === 7) {
      //   this.setBottomComponent('RelaseResSetting')
      // } else {
      this.setBottomComponent('ResourceSetting')
      // }
    }
  },
  watch: {
    orderId: {
      handler (newVal, oldVal) {
        this.getOrderInfo()
        this.getTaskPageData()
      },
      deep: true,
      immediate: true
    },
    demandType (val) {
      if (val !== parseInt(this.query.type)) {
        this.query.type = val.toString()
        this.getTaskPageData()
      }
    }
  }
}
</script>
